import { db } from "../../jsongo";
import { Accordion } from "../../elements";
import {
  BookingDO,
  confirmationUrl,
  isFirstDepositMade,
  MMM_dd_yyyy,
  travelDocumentName,
  travelDocuments,
  travelDocumentUrl,
} from "data-model";
import { ExternalLink, SVG } from "react-components";
import { FC } from "react";
import clsx from "clsx";
import { DateTime } from "luxon";

// const dataCy = "documents";

interface Props {
  booking: BookingDO;
}

const Documents: FC<Props> = ({ booking }) => {
  const canViewTravelDocs = isFirstDepositMade(booking.guests);
  const travelDocs = travelDocuments(db, booking);

  return (
    <Accordion
      title={
        canViewTravelDocs && travelDocs.length ? (
          <span className="is-flex-column">
            Documents
            <span className="has-text-green padding-top-0-pt-5">
              Travel Documents Available
            </span>
          </span>
        ) : (
          "Documents"
        )
      }
      className="margin-y-2"
    >
      <article
        className={clsx(
          "padding-top-4 padding-x-5 padding-bottom-3 has-border-bottom-gray",
          !canViewTravelDocs && "has-text-mid-gray"
        )}
      >
        <h3 className="margin-top-0 margin-bottom-1">Travel Documents:</h3>

        {canViewTravelDocs ? (
          travelDocs.length ? (
            <ul className="has-list-style-type-none padding-left-0 margin-y-0">
              {travelDocs.map((travelDoc) => (
                <li key={travelDoc._id} className="is-flex margin-top-3">
                  <ExternalLink
                    target="_blank"
                    className="is-flex is-flex-1"
                    href={travelDocumentUrl(process.env.PDF_ORIGIN!, travelDoc)}
                  >
                    <SVG
                      className="margin-right-1"
                      path={
                        canViewTravelDocs
                          ? "/site/icon/document"
                          : "/site/icon/document-gray"
                      }
                      alt="Document"
                      height={19}
                    />
                    <strong>{travelDocumentName(travelDoc)}</strong>
                  </ExternalLink>
                  {DateTime.fromISO(travelDoc.effectiveFrom).toFormat(
                    MMM_dd_yyyy
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="is-marginless">
              Documents will be published soon. Please check back later.
            </p>
          )
        ) : (
          <p className="is-marginless">
            Your Hotel List and online Day-by-Day Itinerary will be posted here
            after a deposit payment.
          </p>
        )}
      </article>

      <article className="padding-top-4 padding-3 padding-left-5">
        <h3 className="margin-top-0 margin-bottom-3">Confirmation PDFs:</h3>

        <ul className="has-list-style-type-none padding-left-0 margin-y-0">
          {booking.confirmations.map((confirmation) => (
            <li key={confirmation.id} className="is-flex margin-top-3">
              <ExternalLink
                target="_blank"
                className="is-flex-1 is-flex"
                href={confirmationUrl(process.env.PDF_ORIGIN!, confirmation)}
              >
                <SVG
                  className="margin-right-1"
                  path="/site/icon/document"
                  alt="Document"
                  height={19}
                />
                <strong>{confirmation.type}</strong>
              </ExternalLink>
              {DateTime.fromISO(confirmation.issuedAt).toFormat(
                `${MMM_dd_yyyy}, hh:mm a`
              )}
            </li>
          ))}
        </ul>
      </article>
    </Accordion>
  );
};

export { Documents };
