import { Box, BoxHeading, CommonProps } from "./shared";
import { editTransports } from "../../../api";
import { AccountContext, ACCOUNT_KEY } from "../../../context";
import { airportsById } from "../../../jsongo";
import { FlightEditor } from "../FlightEditor";
import {
  directionFrom,
  draftDefaults,
  fullNameOrPlaceholder,
  GuestDO,
  isGuestAccountOwner,
  isTransportComplete,
  omitBy,
  primaryGuestPosIdx,
  trim,
  TourYearDO,
  TransportDO,
  TransportDO_Direction,
  TransportDraft,
  TransportDrafts,
} from "data-model";
import { Checkbox, TransferInfo } from "react-components";
import { FC, useContext, useRef, useState } from "react";
import { klona } from "klona/json";
import { set } from "idb-keyval";

interface Props extends CommonProps {
  tourYear: TourYearDO;
}

const FlightDetails: FC<Props> = ({
  account,
  booking,
  isUnlocked,
  onClose,
  tourYear,
}) => {
  const isLocked = !isUnlocked;
  const { guests, departure, id: bookingId } = booking;
  const { departedAt, concludedAt } = departure;
  const primaryPosIdx = primaryGuestPosIdx(guests, account);
  const primaryGuest = guests[primaryPosIdx];

  const [, setAccount] = useContext(AccountContext);
  const [transportDrafts, setTransportDrafts] = useState(
    initTransportDrafts(guests, departedAt, concludedAt)
  );
  const [sameAs, setSameAs] = useState(initSameAs(guests, primaryGuest));
  const wrapper = useRef<HTMLDivElement>(null);

  const handleDraftChange = (
    updates: Partial<TransportDraft>,
    posIdx: number,
    isArrival: boolean
  ) =>
    // Functional update prevents a bug where transportDrafts are stale
    // by the time the async flight search finishes.
    setTransportDrafts((currDrafts) => {
      const newTransportDrafts = klona(currDrafts);

      const direction = directionFrom(isArrival);
      const draft = newTransportDrafts[posIdx][direction];
      // Partial update prevents a similar bug where the draft is updated
      // while the flight search is running (ex: stayAtCaravanHotel changes).
      Object.assign(draft, updates);

      if (posIdx === primaryPosIdx) {
        // Whenever primary draft(s) change, sync all guests that have Other checked off
        newTransportDrafts.forEach((drafts, otherPosIdx) => {
          if (otherPosIdx === primaryPosIdx) return; // already updated
          const isSameAs = sameAs[otherPosIdx];
          if (isSameAs) {
            drafts[direction] = draft;
          }
        });
      }

      return newTransportDrafts;
    });

  // Inside this method, posIdx !== primaryPosIdx is always true.
  const handleSameAsChange = (isSame: boolean, posIdx: number) => {
    setSameAs(
      sameAs.map((currIsSame, i) => (i === posIdx ? isSame : currIsSame))
    );

    const primaryDrafts = transportDrafts[primaryPosIdx];
    // When Same is ticked on, set drafts to primary. Otherwise, reset them.
    const newDrafts = isSame
      ? primaryDrafts
      : blankTransportDrafts(departedAt, concludedAt);

    setTransportDrafts(
      transportDrafts.map((currDrafts, i) =>
        i === posIdx ? newDrafts : currDrafts
      )
    );
  };

  const handleSave = async () => {
    // Nested forms are not allowed in HTML, so we must check each form individually.
    for (const form of wrapper.current!.querySelectorAll("form")) {
      if (!form.reportValidity()) return;
    }

    const payload = klona(transportDrafts);
    payload.forEach((drafts) => {
      delete drafts.ARRIVAL.extraNight;
      delete drafts.DEPARTURE.extraNight;
    });
    const guests = await editTransports(bookingId, trim(payload));

    const updatedAccount = {
      ...account,
      bookings: account.bookings.map((b) => {
        if (b.id === bookingId) {
          return {
            ...b,
            guests: b.guests.map((g, posIdx) => {
              const updatedGuest = guests[posIdx];
              return {
                ...g,
                // Exclude unpopulated fields like address, fees, etc.
                arrival: updatedGuest.arrival,
                departure: updatedGuest.departure,
                updatedAt: updatedGuest.updatedAt,
              };
            }),
          };
        }
        return b;
      }),
    };

    await set(ACCOUNT_KEY, updatedAccount);

    setAccount(updatedAccount);

    onClose();
  };

  return (
    <>
      {[true, false].map((isArrival, idx) => (
        <TransferInfo
          key={idx}
          airportsById={airportsById}
          className={idx === 0 ? "is-marginless" : "margin-y-2"}
          departure={departure}
          isArrival={isArrival}
          isUnlocked={isUnlocked}
          tourYear={tourYear}
        />
      ))}

      <p className="margin-y-2">
        <em>Airfare is extra and not sold by Caravan.</em>
      </p>

      <div ref={wrapper}>
        {guests.map((guest, posIdx) => {
          const isSameAs = sameAs[posIdx];
          const isPrimary = posIdx === primaryPosIdx;
          const drafts = transportDrafts[posIdx];

          return (
            <Box key={posIdx} className="margin-bottom-2">
              <BoxHeading
                className="margin-bottom-2"
                isUnlocked={isUnlocked}
                showPerson={isGuestAccountOwner(guests, account, posIdx)}
                showCheckmark={
                  !isPrimary &&
                  isSameAs &&
                  isTransportComplete(
                    drafts.ARRIVAL,
                    departedAt,
                    concludedAt
                  ) &&
                  isTransportComplete(drafts.DEPARTURE, departedAt, concludedAt)
                }
              >
                {fullNameOrPlaceholder(guests, account, posIdx)}
              </BoxHeading>

              {!isPrimary && (
                <>
                  <Checkbox
                    id={`${posIdx}-other`}
                    labelClassName="is-line-height-normal-mobile"
                    disabled={!isUnlocked}
                    checked={isSameAs}
                    onChange={() => handleSameAsChange(!isSameAs, posIdx)}
                  >
                    Same flight details as{" "}
                    {fullNameOrPlaceholder(guests, account, primaryPosIdx)}
                  </Checkbox>

                  {!isSameAs && <hr className="divider is-slim margin-y-3" />}
                </>
              )}

              {(isPrimary || !isSameAs) && (
                <>
                  <BoxHeading
                    className="margin-y-2"
                    heading="h4"
                    isUnlocked={isUnlocked}
                    showCheckmark={isTransportComplete(
                      drafts.ARRIVAL,
                      departedAt,
                      concludedAt
                    )}
                  >
                    Arrival
                  </BoxHeading>

                  <FlightEditor
                    departure={departure}
                    draft={drafts.ARRIVAL}
                    isUnlocked={isUnlocked}
                    onDraftChange={handleDraftChange}
                    positionalIdx={posIdx}
                    tourYear={tourYear}
                    transport={guest.arrival}
                  />

                  <hr className="divider is-slim margin-y-3" />

                  <BoxHeading
                    className="margin-y-2"
                    heading="h4"
                    isUnlocked={isUnlocked}
                    showCheckmark={isTransportComplete(
                      drafts.DEPARTURE,
                      departedAt,
                      concludedAt
                    )}
                  >
                    Departure
                  </BoxHeading>

                  <FlightEditor
                    departure={departure}
                    draft={drafts.DEPARTURE}
                    isUnlocked={isUnlocked}
                    onDraftChange={handleDraftChange}
                    positionalIdx={posIdx}
                    tourYear={tourYear}
                    transport={guest.departure}
                  />
                </>
              )}
            </Box>
          );
        })}

        <button
          className="button is-yellow is-fullwidth"
          onClick={handleSave}
          disabled={isLocked}
        >
          Save and Continue
        </button>
      </div>
    </>
  );
};

export { FlightDetails };

const initTransportDrafts = (
  guests: GuestDO[],
  tourStarts: string,
  tourEnds: string
) =>
  guests.map((guest) => {
    // guest.{arrival,departure} are of type TransportDO|undefined, hence the defaults
    const drafts = blankTransportDrafts(tourStarts, tourEnds);
    overwriteDraftWithTransport(
      drafts[TransportDO_Direction.ARRIVAL],
      guest.arrival
    );
    overwriteDraftWithTransport(
      drafts[TransportDO_Direction.DEPARTURE],
      guest.departure
    );
    return drafts;
  });

const blankTransportDrafts = (tourStarts: string, tourEnds: string) => {
  const drafts: TransportDrafts = {
    [TransportDO_Direction.ARRIVAL]: {
      ...draftDefaults,
      isArrival: true,
      isFlight: true,
      scheduledDate: tourStarts,
    },
    [TransportDO_Direction.DEPARTURE]: {
      ...draftDefaults,
      isArrival: false,
      isFlight: true,
      scheduledDate: tourEnds,
    },
  };
  return drafts;
};

export const makeDraftFrom = (transport: TransportDO) =>
  omitBy(transport, ["id", "isManual", "createdAt", "updatedAt"]);

const overwriteDraftWithTransport = (
  draft: TransportDraft,
  transport?: TransportDO
) => {
  if (transport) {
    Object.assign(draft, makeDraftFrom(transport));
  }
  return draft;
};

const initSameAs = (guests: GuestDO[], primaryGuest: GuestDO) => {
  return guests.map((guest) => {
    if (guest === primaryGuest) return false; // N/A even though technically true
    return (
      guest.arrival?.id === primaryGuest.arrival?.id &&
      guest.departure?.id === primaryGuest.departure?.id
    );
  });
};
