import { toursById } from "../../jsongo";
import { AccountDO, BookingDO, CENTRAL_TZ, surveyLink } from "data-model";
import { ExternalLink, SVG } from "react-components";
import { DateTime } from "luxon";
import { FC } from "react";

interface Props {
  account: AccountDO;
  booking: BookingDO;
}

const TourSurvey: FC<Props> = ({ account, booking }) => {
  const { concludedAt } = booking.departure;

  if (!isSurveyAvailable(concludedAt)) return null;

  const { surveyOpened, surveyClosed } = surveySchedule(concludedAt);

  if (!surveyOpened) {
    return (
      <p className="margin-y-3">
        <strong>
          Your Tour Survey will be available here in Manage Booking after your
          tour has ended.
        </strong>
      </p>
    );
  }

  return booking.surveys || window.location.search === "?reviewed" ? (
    <section className="margin-y-3">
      <p className="is-flex has-text-green">
        <SVG
          className="margin-right-1"
          path="/site/icon/checkmark-circled-green"
          alt="Checkmark"
          height={19}
        />
        <strong>Thank you! We received your survey.</strong>
      </p>
    </section>
  ) : !surveyClosed ? (
    <p className="margin-y-3">
      <ExternalLink
        href={surveyLink(
          account,
          booking,
          toursById[booking.tour].name,
          window.location.origin
        )}
      >
        <strong>Your Tour Survey is Now Available</strong>
      </ExternalLink>
      <br />
      Let us know how we did.
    </p>
  ) : null;
};

export { TourSurvey };

const SURVEY_LAUNCHED_AT = "2024-01-01";
const isSurveyAvailable = (concludedAt: string) =>
  concludedAt >= SURVEY_LAUNCHED_AT;

const surveySchedule = (concludedAt: string) => {
  const noon = DateTime.fromISO(`${concludedAt}T12:00:00`, {
    zone: CENTRAL_TZ,
  });
  const now = DateTime.now();
  return {
    surveyOpened: now >= noon,
    // surveyClosed: now >= noon.plus({ days: 90 }),
    surveyClosed: false,
  };
};
