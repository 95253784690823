import { syncAccount } from "./api";
import { Header } from "./components";
import { ACCOUNT_KEY, AccountContext, AccountProvider } from "./context";
import {
  BookingFAQs,
  BookingPlayground,
  Bookings,
  // CancelOptions,
  // CancelPreview,
  // CancelFinal,
  CancellationPlayground,
  EditAccount,
  EmailReconfirmation,
  FlightPlayground,
  HoldLimit,
  HoldSpace,
  InvalidLink,
  InviteFriends,
  LogIn,
  ManageBooking,
  MessagePlayground,
  MyAccount,
  NotFound,
  ResetPassword,
  SignUp,
  SpaceUnavailable,
  TimelinePlayground,
} from "./pages";
import { LocationObserver } from "./utils";
import {
  CvnProvider,
  ModalProvider,
  RequiresAuth,
  RequiresGuest,
  fakeContext,
} from "react-components";
import { get, set } from "idb-keyval";
import { FC, useContext, useEffect, useState } from "react";
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";

const cvnContext = {
  ...fakeContext,
  routerLink: Link,
};

const App: FC = () => (
  <CvnProvider value={cvnContext}>
    <AccountProvider>
      <Router>
        <ModalProvider>
          <Main />
          <LocationObserver />
        </ModalProvider>
      </Router>
    </AccountProvider>
  </CvnProvider>
);

export const Main: FC = () => {
  const [account, setAccount] = useContext(AccountContext);
  const isLoggedIn = !!account;

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    async function init() {
      let account = await get(ACCOUNT_KEY);

      if (account) {
        try {
          // Refresh the cache in case it's stale
          account = await syncAccount();
          await set(ACCOUNT_KEY, account);
        } catch (e: any) {
          if (e.status === 401) throw e; // db is now cleared by onError in apiHelpers; let the page reload
          console.error(e); // 500, timeout, network error
        }
        setAccount(account);
      }

      setIsReady(true);
    }

    init();

    // TODO on WS disconnect => reconnect & sync cache
    // TODO freeze => close WS and idb
    // TODO resume => reestablish WS and idb, invalidate cache
  }, []);

  if (!isReady) return null; // <Loading />;

  return (
    <>
      <Header />
      <main className="is-flex-column is-flex-1">
        <Routes>
          <Route path="/" element={<HoldSpace />} />
          <Route
            path="/login"
            element={
              <RequiresGuest auth={isLoggedIn} redirectTo="/bookings">
                <LogIn />
              </RequiresGuest>
            }
          />
          <Route
            path="/signup"
            element={
              <RequiresGuest auth={isLoggedIn} redirectTo="/bookings">
                <SignUp />
              </RequiresGuest>
            }
          />
          <Route
            path="/hold-limit"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <HoldLimit />
              </RequiresAuth>
            }
          />
          <Route
            path="/space-unavailable"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <SpaceUnavailable />
              </RequiresAuth>
            }
          />
          <Route
            path="/bookings"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <Bookings />
              </RequiresAuth>
            }
          />
          <Route
            path="/bookings/cancelled"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <Bookings cancelled />
              </RequiresAuth>
            }
          />
          <Route
            path="/bookings/travelled"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <Bookings travelled />
              </RequiresAuth>
            }
          />
          <Route
            path="/bookings/expired"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <Bookings expired />
              </RequiresAuth>
            }
          />
          <Route
            path="/account"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <MyAccount />
              </RequiresAuth>
            }
          />
          <Route
            path="/account/edit"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <EditAccount />
              </RequiresAuth>
            }
          />
          <Route
            path="/bookings/:bookingNumber/invite"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <InviteFriends />
              </RequiresAuth>
            }
          />
          <Route
            path="/bookings/:bookingNumber"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <ManageBooking />
              </RequiresAuth>
            }
          />
          <Route
            path="/bookings/:bookingNumber/faqs"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <BookingFAQs />
              </RequiresAuth>
            }
          />
          {/* <Route
            path="/bookings/:bookingNumber/cancel"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <CancelOptions />
              </RequiresAuth>
            }
          />
          <Route
            path="/bookings/:bookingNumber/cancel/preview"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <CancelPreview />
              </RequiresAuth>
            }
          />
          <Route
            path="/bookings/:bookingNumber/cancel/final"
            element={
              <RequiresAuth auth={isLoggedIn}>
                <CancelFinal />
              </RequiresAuth>
            }
          /> */}
          <Route
            path="/password/reset"
            element={
              <RequiresGuest auth={isLoggedIn} redirectTo="/bookings">
                <ResetPassword />
              </RequiresGuest>
            }
          />
          <Route
            path="/password/invalid"
            element={
              <RequiresGuest auth={isLoggedIn} redirectTo="/bookings">
                <InvalidLink />
              </RequiresGuest>
            }
          />
          <Route path="/confirmation/email" element={<EmailReconfirmation />} />
          {(process.env.WEBPACK_MODE === "development" ||
            process.env.ENV_AREA === "staging") && (
            <>
              <Route path="/flight-playground" element={<FlightPlayground />} />
              <Route
                path="/booking-playground"
                element={<BookingPlayground />}
              />
              <Route
                path="/timeline-playground"
                element={<TimelinePlayground />}
              />
              <Route
                path="/cancellation-playground"
                element={<CancellationPlayground />}
              />
              <Route
                path="/message-playground"
                element={<MessagePlayground />}
              />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </>
  );
};

export { App };
