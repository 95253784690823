import { emailReconfirmation } from "../api";
import { Container } from "../elements";
import { SVG } from "react-components";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const EmailReconfirmation: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const signature = searchParams.get("signature");
  const isAlreadySent = searchParams.has("sent");

  const [email, setEmail] = useState("");
  const [hasError, setHasError] = useState(!id || !signature);
  const isSending = !hasError && !isAlreadySent;

  useEffect(() => {
    if (!id || !signature || isAlreadySent) return;
    const fn = async () => {
      try {
        const { email } = await emailReconfirmation(+id, { signature });
        setEmail(email);
        setHasError(false);

        // To prevent accidental reload of the page:
        searchParams.append("sent", "");
        setSearchParams(searchParams);
      } catch (e) {
        setHasError(true);
      }
    };
    fn();
  }, []);

  return (
    <Container>
      <h1 className="is-marginless">Confirmation PDF Email</h1>
      <hr className="divider is-red" />

      {isSending ? (
        <SVG
          path="/site/icon/spinner"
          className="margin-top-4 margin-x-auto"
          alt="Spinner"
          height={30}
        />
      ) : hasError ? (
        <>
          <p>
            This link is invalid; your PDF could not be emailed. Please retry
            clicking the link that was sent to you by email.
          </p>

          <p>If you are having trouble, please call Caravan Client Services.</p>
        </>
      ) : (
        <p>
          Success! Your Confirmation PDF has been emailed
          {email ? (
            <>
              {" "}
              to <strong>{email}</strong>
            </>
          ) : (
            <>
              . To request another confirmation, please click the link that was
              sent to you by email.
            </>
          )}
        </p>
      )}
    </Container>
  );
};

export { EmailReconfirmation };
